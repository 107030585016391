import * as React from "react"
import logo from "../../images/logo.png"
import Container from "../container"

export default function Hero() {
  return (
    <Container className={"justify-between items-center py-12 px-6 flex-col"}>
      <div>
        <img alt="Can Tho Kitchen logo" src={logo} />
      </div>
    </Container>
  )
}
