import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import HeaderBar from "../components/header-bar"
import Container from "../components/container"
import { CgSpinner } from "react-icons/all"

const ReviewsPage = () => {
  return (
    <Layout>
      <Hero />
      <HeaderBar />
      <div className={"bg-white"} id={"about"}>
        <Container className={"px-6 py-12 md:gap-8 flex-wrap justify-center"}>
          <div className={"flex flex-col items-center justify-center"}>
            <h1 className={"text-4xl mb-6 uppercase"}>Reviews</h1>
            <div className={"max-w-6xl"}>
              <div className="elfsight-app-714bf4ab-aca3-42c8-8796-8b04a0fa5b71">
                <CgSpinner className={"animate-spin"} />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default ReviewsPage
